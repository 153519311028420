import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const ContactUsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #3498db, #6c5ce7); /* Light gray background */
`;

const ContactUsContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white; /* Set text color to white */
`;

const ContactForm = styled.form`
  width: 60%;
  max-width: 400px;
  margin-bottom: 20px; /* Add margin to create space between form and address */
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const AddressWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: black; /* Set background color to black */
`;

const OfficeAddress = styled.div`
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  color: white; /* Set text color to white */
`;

const GoogleMapWrapper = styled.div`
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
`;

const GoogleMap = styled.iframe`
  width: 100%;
  height: 200px;
`;

const Footer = styled.footer`
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
`;

const ContactUs = () => (
  <ContactUsContainer id="contact-us">
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ContactPage",
          "url": "https://lampyridcompilers.com/contact",
          "name": "Contact Us",
          "description": "Get in touch with Lampyrid Compilers."
        })}
      </script>
    </Helmet>
    <ContactUsContent>
      <h1>Contact Us</h1>
      <p>Feel free to get in touch with us. We're here to help!</p>
      <ContactForm action="https://formspree.io/f/mpzejldq" method="POST">
        <FormGroup>
          <Label htmlFor="name">Your Name</Label>
          <Input type="text" id="name" name="name" required />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Your Email</Label>
          <Input type="email" id="email" name="email" required />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="message">Your Message</Label>
          <TextArea id="message" name="message" rows="4" required />
        </FormGroup>
        <Button type="submit">Send Message</Button>
      </ContactForm>
    </ContactUsContent>
    <AddressWrapper>
      <OfficeAddress>
        <p>Office Address: Office No:-108,1st Floor , Valhekar Heights, Mumbai-Bengaluru Highway, Narhe, near HDFC Bank, Pune, Maharashtra 411041</p>
        <p>Contact Number: 07767999983</p>
        <p>Email: lampyridcompilers@gmail.com</p>
      </OfficeAddress>
      <GoogleMapWrapper>
        <GoogleMap
          title="Google Map"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.665139091868!2d73.82558231386696!3d18.45350707604048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2953990901bc3%3A0x690eafbe97873207!2sLampyrid%20Compilers!5e0!3m2!1sen!2sin!4v1679417261659!5m2!1sen!2sin" 
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></GoogleMap>
      </GoogleMapWrapper>
    </AddressWrapper>
    <Footer>
      <p>&copy; 2024 All rights reserved.lampyridcompilers.com</p>
    </Footer>
  </ContactUsContainer>
);

export default ContactUs;
