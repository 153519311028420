import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background: linear-gradient(135deg, #3498db, #6c5ce7);
  color: white;
  min-height: 100vh;
`;

const Section = styled.div`
  background: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  text-align: center;
  margin: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
`;

const SectionText = styled.p`
  font-size: 1.2em;
  color: #333;
  line-height: 1.5;
`;

const ValuesList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 20px;
`;

const ValueItem = styled.li`
  font-size: 1.2em;
  margin: 10px 0;
  display: flex;
  align-items: center;
  color:black;
`;

const ValueIcon = styled.span`
  font-size: 1.5em;
  margin-right: 10px;
  color: #3498db;
`;

const AboutUs = () => (
  <AboutContainer>
    <Section>
      <SectionTitle>Vision</SectionTitle>
      <SectionText>
        Our vision is to become the global leader in embedded systems and innovative technology solutions, transforming industries and improving lives. At LAMPYRID COMPILERS, we strive to be the most trusted and respected organization, known for our unwavering commitment to excellence, sustainability, and social responsibility. We are dedicated to making a positive impact on our customers, investors, associates, families, and society at large.
      </SectionText>
    </Section>

    <Section>
      <SectionTitle>Mission</SectionTitle>
      <SectionText>
        Our mission is to deliver innovative and reliable solutions from “Concept to Product” in embedded systems consultancy, product design, and manufacturing. We aim to exceed our customers' expectations through cutting-edge technology, unparalleled expertise, and a relentless focus on quality and service. By fostering a culture of innovation and continuous improvement, we empower our clients to achieve their goals and drive success in their respective fields.
      </SectionText>
    </Section>

    <Section>
      <SectionTitle>Values</SectionTitle>
      <ValuesList>
        <ValueItem><ValueIcon>🚀</ValueIcon>Innovativeness: We embrace creativity and technological advancements to deliver state-of-the-art solutions.</ValueItem>
        <ValueItem><ValueIcon>📚</ValueIcon>Knowledge: We believe in continuous learning and development to stay ahead in the industry.</ValueItem>
        <ValueItem><ValueIcon>🤝</ValueIcon>Partnership: We build strong, collaborative relationships with our clients, partners, and employees.</ValueItem>
        <ValueItem><ValueIcon>🌱</ValueIcon>Development: We are committed to personal and professional growth, fostering a culture of excellence.</ValueItem>
        <ValueItem><ValueIcon>⚖️</ValueIcon>Responsibility: We act with integrity and accountability, ensuring sustainable and ethical practices.</ValueItem>
        <ValueItem><ValueIcon>🌐</ValueIcon>Open-mindedness: We value diverse perspectives and encourage open communication and idea-sharing.</ValueItem>
        <ValueItem><ValueIcon>🎯</ValueIcon>Goal Orientation: We are driven by clear objectives and a relentless pursuit of achieving them.</ValueItem>
      </ValuesList>
    </Section>
  </AboutContainer>
);

export default AboutUs;
