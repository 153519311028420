import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dialog = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative; /* Ensure close button position is relative to dialog */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #f44336; /* Red color for close button */
  color: white; /* Text color for close button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const ProductDialog = ({ product, onClose }) => {
  // Split the product description into an array of bullet points
  const descriptionArray = product.description.split('\n');

  return (
    <Overlay>
      <Dialog>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <h2>{product.name}</h2>
        <ul>
          {descriptionArray.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </Dialog>
    </Overlay>
  );
};

export default ProductDialog;
