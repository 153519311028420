// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import OurProducts from "./components/OurProducts";
import Navigation from "./components/NavBar";

const App = () => (
  <Router>
    <Navigation />
    <div style={{ paddingTop: "70px" }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-products" element={<OurProducts />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </div>
  </Router>
);

export default App;
