import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ProductDialog from './DialogOverlay'; // Import the dialog component

const OurProductsContainer = styled.div`
  margin-top: 20px; /* Add margin from the top */
  background: linear-gradient(135deg, #3498db, #6c5ce7); /* Light gray background */
`;

const OurProductsContent = styled.div`
  text-align: center;
  padding: 20px;
  color: white; /* Set text color to white */
 
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Dynamic column size */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: auto; /* Horizontal scrolling */
  padding-bottom: 20px; /* Add padding to the bottom for better visibility */
`;

const ProductItem = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; /* Set cursor to pointer for clickable effect */
`;

const ProductImage = styled.img`
  width: 120px; /* Adjust the width of the image */
  height: 120px; /* Adjust the height of the image */
  margin-bottom: 10px;
`;

const ProductName = styled.h3`
  font-size: 1.5em;
  color: black;
  margin-bottom: 10px;
`;

const OurProducts = () => {
  const [selectedProduct, setSelectedProduct] = useState(null); // State to store selected product

  // Function to handle click event on product
  const handleProductClick = (product) => {
    setSelectedProduct(product); // Set the selected product in state
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setSelectedProduct(null); // Clear the selected product
  };

  // Function to render the product dialog if a product is selected
  const renderProductDialog = () => {
    if (selectedProduct) {
      return <ProductDialog product={selectedProduct} onClose={handleCloseDialog} />;
    }
    return null;
  };

  // Mock data for products
  const products = [
    { id: 1, name: 'Autoswitch AA 100', image: '/images/na.jpg', description: '• Microcontroller based technology.\n• Motor starts automatically as supply is ON in 1/2/3/4/5 minutes.\n• Protection from unbalance voltage.\n• Protects the motor from single phasing\n• Operates on low voltage (from 180 V)\n• Useful for 3 Ph. 3 to 7.5 H.P. (D.O.L. Starter) Openwell, Submersible, Monoblock pumpsets.\n• Separate indications for Timer, OK and SPP\n• Auto Manual Facility.\n• 6 month Replacement Warranty' },
    { id: 2, name: 'Autoswitch AA 200', image: '/images/na.jpg', description: 'Description of Product 2' },
    { id: 3, name: 'Autoswitch SD-100', image: '/images/na.jpg', description: 'Description of Product 3' },
    { id: 4, name: 'Autoswitch DR 100', image: '/images/na.jpg', description: 'Description of Product 4' },
    { id: 5, name: 'Autoswitch DR 200 (Stop Timer)', image: '/images/na.jpg', description: 'Description of Product 5' },
    { id: 6, name: 'Autoswitch DR 200 (Start Timer)', image: '/images/na.jpg', description: 'Description of Product 6' },
    { id: 7, name: 'Autoswitch SDDR 100', image: '/images/na.jpg', description: 'Description of Product 7' },
    { id: 8, name: 'Autoswitch SDDR 200 (STOP TIMER)', image: '/images/na.jpg', description: 'Description of Product 8' },
    { id: 9, name: 'SINGLE PHASE CONTROL PANEL WITH STOP TIMER (SPP)', image: '/images/na.jpg', description: 'Description of Product 9' },
    { id: 10, name: '3 PH. STARTER WITH AUTOSWITCH (TPP)', image: '/images/na.jpg', description: 'Description of Product 10' },
    // Add more product data as needed
  ];

  // Structured data for products
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": products.map((product, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `https://lampyridcompilers.com/product/${product.id}`,
      "name": product.name,
      "image": `https://lampyridcompilers.com${product.image}`,
      "description": product.description
    }))
  };

  return (
    <OurProductsContainer id="our-products">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <OurProductsContent>
        <h1>Our Products</h1>
        <ProductGrid>
          {products.map((product) => (
            <ProductItem key={product.id} onClick={() => handleProductClick(product)}>
              <ProductImage src={product.image} alt={product.name} />
              <ProductName>{product.name}</ProductName>
            </ProductItem>
          ))}
        </ProductGrid>
      </OurProductsContent>
      {renderProductDialog()}
    </OurProductsContainer>
  );
};

export default OurProducts;
