// components/Home.js
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { FaCheckCircle, FaLightbulb, FaSmile } from 'react-icons/fa'; // Import icons

// Styled components for the Home page
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 20px 40px; /* Top padding added to avoid navbar overlap */
  background: linear-gradient(135deg, #3498db, #6c5ce7); /* Gradient background */
  color: #333; /* Dark text color */
`;

const Section = styled.div`
  background: rgba(255, 255, 255, 0.8); /* Transparent white background */
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  text-align: center;
  margin: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Box shadow */
`;

const SectionTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333; /* Darker text color for better contrast */
`;

const Text = styled.p`
  font-size: 1.2em;
  color: #555; /* Medium dark text color for better readability */
  line-height: 1.6;
`;

const StyledTitle = styled.h1`
  font-size: 3em; /* Font size */
  color: white; /* White text color */
  font-family: 'Arial Black', sans-serif; /* Font family */
`;

const Description = styled.p`
  font-size: 1.5em;
  color: white; 
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FeatureIcon = styled.div`
  font-size: 2.5em;
  color: #3498db;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const FeatureContent = styled.div`
  max-width: 600px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  font-size: 1.2em;
  color: #555;
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.8); /* Transparent white background */
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Box shadow */
`;

const Testimonial = styled.blockquote`
  font-size: 1.2em;
  margin: 10px 0;
  font-style: italic;
`;

const Footer = styled.footer`
  background: #333;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  margin-top: 40px;
`;

const Home = () => (
  <HomeContainer>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://lampyridcompilers.com",
          "name": "LC AUTO SWITCH",
          "description": "Your one-stop solution for reliable and innovative products."
        })}
      </script>
    </Helmet>
    <StyledTitle>Welcome to Lampyrid Compilers</StyledTitle>
    <Description>Your one-stop solution for reliable and innovative products.</Description>

    <Section>
      <SectionTitle>About Us</SectionTitle>
      <Text>
        We LAMPYRID COMPILERS has been incorporated in 2016, a leading manufacturer for single phasing Preventors, auto switches, timers, cyclic timers, and control panels for single-phase and three-phase motors. Our products are high quality, safe for children and environment-friendly, widely used in the field of agriculture, irrigation, industry, and residential complexes.
      </Text>
      <Text>
        We Lampyrid Compilers aim to facilitate and resolve any issues related to water management for different water levels in order to avoid wastage of water and electricity.
      </Text>
      <Text>
        Lampyrid Compilers believes in manufacturing quality products and providing reliable after-sale service to the farmers/customers who face the two basic problems of water supply and electricity. A team of efficient technical support engineers, we provide 24*7 support to our customers.
      </Text>
      <Text>
        We are proud that all products are MADE IN INDIA.
      </Text>
    </Section>

    <Section>
      <SectionTitle>Our Features</SectionTitle>
      <FeatureList>
        <FeatureItem>
          <FeatureIcon>
            <FaCheckCircle />
          </FeatureIcon>
          <FeatureContent>
            <FeatureTitle>High-Quality Products</FeatureTitle>
            <FeatureDescription>
              Our products are built with the highest quality materials and craftsmanship, ensuring durability and reliability. We guarantee that our products will exceed your expectations and meet your needs.
            </FeatureDescription>
          </FeatureContent>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>
            <FaLightbulb />
          </FeatureIcon>
          <FeatureContent>
            <FeatureTitle>Innovative Solutions</FeatureTitle>
            <FeatureDescription>
              We are at the forefront of innovation, constantly developing new and creative solutions to solve your problems. Our team of experts is dedicated to bringing you the latest in technology and design.
            </FeatureDescription>
          </FeatureContent>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>
            <FaSmile />
          </FeatureIcon>
          <FeatureContent>
            <FeatureTitle>Customer Satisfaction</FeatureTitle>
            <FeatureDescription>
              Your satisfaction is our top priority. We go above and beyond to ensure that you are happy with our products and services. Our customer support team is always here to assist you with any questions or concerns.
            </FeatureDescription>
          </FeatureContent>
        </FeatureItem>
      </FeatureList>
    </Section>

    <TestimonialContainer>
      <SectionTitle>What Our Clients Say</SectionTitle>
      <Testimonial>
        "LC AUTO SWITCH has transformed our business with their innovative solutions."
      </Testimonial>
      <Testimonial>
        "The quality of their products is unmatched. Highly recommended!"
      </Testimonial>
    </TestimonialContainer>

    <Footer>© 2024 LC AUTO SWITCH. All Rights Reserved.</Footer>
  </HomeContainer>
);

export default Home;
