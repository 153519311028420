// components/NavBar.js
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/lcheader.jpg"; // Ensure the path is correct
import { FaBars } from "react-icons/fa"; // Import hamburger icon

const NavBar = styled.nav`
  position: fixed;
  width: 100%;
  background: #444;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 15px;
`;

const CompanyName = styled.span`
  color: white;
  font-size: 2em;
  font-weight: bold;
`;

const NavItems = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    display: ${(props) => (props.open ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #444;
    padding: 10px 0;
    text-align: center;
  }
`;

const NavItem = styled(RouterLink)`
  margin: 0 20px;
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  cursor: pointer;

  &:hover {
    color: #3498db;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 10px 0;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  color: white;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <NavBar>
      <LogoContainer>
        <Logo src={logo} alt="LC AUTO SWITCH" />
        <CompanyName>Lampyrid Compilers</CompanyName>
      </LogoContainer>
      <Hamburger onClick={toggleMenu}>
        <FaBars size={24} />
      </Hamburger>
      <NavItems open={menuOpen}>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/our-products">Our Products</NavItem>
        <NavItem to="/about-us">About Us</NavItem>
        <NavItem to="/contact-us">Contact Us</NavItem>
      </NavItems>
    </NavBar>
  );
};

export default Navigation;
